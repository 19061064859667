import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Helmet from "react-helmet"
import Layout from "../../components/Layout"
import PostCardLink from "../../components/PostCardLink"
import { useIntl } from "react-intl"

const postsQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { pageType: { eq: "post" } }
        fields: { langKey: { eq: "lv" } }
      }
    ) {
      edges {
        node {
          id
          fields {
            langKey
            slug
          }
          frontmatter {
            title
            mainDescription
            listImage {
              childImageSharp {
                fixed(width: 1000, quality: 75) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            mainImage {
              childImageSharp {
                fixed(width: 1000, quality: 75) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`

const BlogPageTemplate = ({ posts }) => {
  const intl = useIntl()

  return (
    <div className="container mx-auto py-8 md:px-8">
      <Helmet>
        <title>{intl.formatMessage({ id: "nav.blog" })}</title>
      </Helmet>
      {posts.map(({ node: post }, i) => (
        <PostCardLink
          i={i}
          key={post.id}
          {...post.fields}
          {...post.frontmatter}
        />
      ))}
    </div>
  )
}

const BlogPage = ({ location, data }) => {
  const { edges: posts } = data.allMarkdownRemark

  return (
    <Layout location={location}>
      <BlogPageTemplate posts={posts} />
    </Layout>
  )
}

const LocalizedBlogPage = ({ location }) => {
  const data = useStaticQuery(postsQuery)
  return <BlogPage location={location} data={data} />
}

export default LocalizedBlogPage
